import * as request from '@/utils/request'
// import Promise from 'lodash/_Promise'

export default {
  /**
   * getPlatformList
   * @description 分类管理列表
   * @param {*} data
   * @returns
   */
  getPlatformList(data) {
    return request.post('/platform/list', data)
  }, /**
   * getPlatformList
   * @description 分类管理列表
   * @param {*} data
   * @returns
   */
  getPlatformAll(data) {
    return request.post('/platform/all', data)
  }, /**
   * savePlatform
   * @description 保存分类
   * @param {*} data
   * @returns
   */
  savePlatform(data) {
    return request.post('/platform/store', data)
  }, /**
   * getPlatformInfo
   * @description 获取详情
   * @param {*} data
   * @returns
   */
  getPlatformInfo(id) {
    let data = { id: id }
    return request.post('/platform/get_info', data)
  }, /**
   * deletePlatformInfo
   * @description 删除分类详情
   * @author YQ
   * @date 2019-01-19
   * @param {*} id
   * @returns
   */
  deletePlatformInfo(id) {
    let data = { id: id }
    return request.post('/platform/delete', data)
  }, /**
   * @description 排序
   * @param {*} ids
   * @returns
   */
  orderPlatform(ids) {
    let data = { ids: ids }
    return request.post('/platform/order', data)
  },

  /**
   * getPlatformSettingList
   * @description 分类管理列表
   * @param {*} data
   * @returns
   */
  getPlatformSettingList(data) {
    return request.post('/platform_setting/list', data)
  }, /**
   * savePlatformSetting
   * @description 保存
   * @param {*} data
   * @returns
   */
  savePlatformSetting(data) {
    return request.post('/platform_setting/store', data)
  }, /**
   * getPlatformSettingInfo
   * @description 获取详情
   * @param {*} data
   * @returns
   */
  getPlatformSettingInfo(id) {
    let data = { id: id }
    return request.post('/platform_setting/get_info', data)
  }, /**
   * getPlatformSettingInfoByPlatformId
   * @param platform_id
   * @returns {Promise}
   */
  getPlatformSettingInfoByPlatformId(platform_id) {
    let data = { platform_id: platform_id }
    return request.post('/platform_setting/get_info_by_pi', data)
  }, /**
   * 根据平台代号查询平台配置
   * @param platform_code
   * @returns {Promise}
   */
  getPlatformSettingInfoByPlatformCode(platform_code) {
    let data = { platform_code: platform_code }
    return request.post('/platform_setting/get_info_by_pc', data)
  }, /**
   * deletePlatformSettingInfo
   * @description 删除分类详情
   * @author YQ
   * @date 2019-01-19
   * @param {*} id
   * @returns
   */
  deletePlatformSettingInfo(id) {
    let data = { id: id }
    return request.post('/platform_setting/delete', data)
  },
  getKolJournalList(data) {
    return request.post('/artist_journal/list', data)
  }, //刊例配置
  // 列表
  getJournalList(data) {
    return request.post('/journal/list', data)
  },
  getJournalStore(data) {
    return request.post('/journal/store', data)
  },
  delJournalStore(data) {
    return request.post('/journal/delete', data)
  }
}
