import { numberFormat, parseTime } from './utils'

export class RegisterFilter {
  /**
   * @param Vue
   */
  constructor(Vue) {
    Vue.filter('numberFormat', function(value, decimals = 0) {
      return numberFormat(value, decimals)
    })

    Vue.filter('numberFormatW', function(value, decimals = 2) {
      if (value >= 1e4) {
        const num = ((parseFloat(value) / 1e4)).toFixed(decimals)
        return numberFormat(num, decimals) + 'w'
      } else {
        return numberFormat(value)
      }
    })

    Vue.filter('parseTime', function(time, cFormat = '{y}/{m}/{d}') {
      return parseTime(time, cFormat)
    })

    Vue.filter('percent', function(value, decimals = 1) {

      value = value ? (parseFloat(value) > 1 ? parseFloat(value) : parseFloat(value) * 100) : 0
      return numberFormat(value, decimals) + '%'
    })
  }
}
