// export default {
//   path: 'trade',
//   name: 'trade',
//   component: () => import(/* webpackChunkName: "trend-list" */ '@/pages/trade/index'),
//   meta: {title: '平台数据'},
//   children: [
//     {
//       path: 'import-main',
//       name:'trade-import-main',
//       component: () => import('@/pages/trade/import/index'),
//       meta: {title: '导入记录'},
//     },
//     {
//       path: 'import',
//       name:'trade-import',
//       component: () => import('@/pages/trade/import/TradeImport'),
//       meta: {title: '导入记录'},
//     }
//   ]
// }
//

/**
 * 自动生成 router代码【模块名：平台订单数据】
 * 生成日期：2024/11/21 14:37:32
 * 生成路径: src/router/trade.js
 * 生成人：超级管理员
 * 需要在文件 src/router/router.js 引入当前文件
 * import trade from './trade'
 // 路由详情
 const routes = [
 {
     path: '/',
     component: () => import('@/layouts/BasicLayout'),
     name: 'BasicLayout',
     meta: { title: '首页' },
     redirect: '/myNav',
     children: [
     ...
      trade,//平台订单数据
     ]
 */

export default  {
  path: 'trade',
  name: 'trade',
  component: () => import('@/pages/trade/index'),
  meta: { title: '平台数据' },
  children: [
    {
      path: 'list',
      name:'trade-list',
      component: () => import('@/pages/trade/TradeList'),
      meta: {title: '数据列表'},
    },
    {
      path: 'import',
      name:'trade-import',
      component: () => import('@/pages/trade/import/TradeImport'),
      meta: {title: '导入预览'},
    }
  ]
}
