
/**
 * RegisterDirectives
 * 注册指令
 */
export class RegisterDirectives {
  /**
   * @param Vue
   */
  constructor(Vue) {

    Vue.directive('watermark', {
      inserted: function(el, binding) {
        let text = binding.value
        let font = '20px 微软雅黑'
        let textColor = 'rgba(215, 215, 215, 0.3)'
        let width = 400
        let height = 200
        let textRotate = -20

        function addWaterMarker(parentNode) {
          const can = document.createElement('canvas')
          parentNode.appendChild(can)
          can.width = width
          can.height = height
          can.style.display = 'none'
          const cans = can.getContext('2d')
          cans.rotate(textRotate * Math.PI / 180)
          cans.font = font
          cans.fillStyle = textColor
          cans.textAlign = 'left'
          cans.textBaseline = 'Middle'
          cans.fillText(text, 0, can.height)
          parentNode.style.backgroundImage = 'url(' + can.toDataURL('image/png') + ')'
        }

        addWaterMarker(el)
      }
    })
  }
}
